export function orderQuery(queryObject: QueryFields | QueryParams, order: Query): URLSearchParams {
    const orderedQuery = Object.values(order)
        .reduce<Query>((object, key: string) => {
            return {...object, [key]: ""}
        }, {})

    Object.entries(queryObject).forEach(([key, value]) => {
        const assignedKey = order[key] ?? key
        if (!orderedQuery.hasOwnProperty(assignedKey)) return

        if (key !== 'radius') {
            orderedQuery[assignedKey] = Array.isArray(value) ? value.sort().join(',') : value.toString()
        } else if (Array.isArray(value) && value.length) {
            const radiusValues = value.sort().map((radius: string) => {
                const valueArray = radius.split('+')
                return { id: valueArray[0], radius: valueArray[1] ?? '0' }
            }).filter(city => city.radius != '0')

            if (value.length > 1) {
                radiusValues.forEach(city => {
                    orderedQuery[`${assignedKey}[${city.id}]`] = city.radius
                })
            } else if (radiusValues.length) {
                orderedQuery[assignedKey] = radiusValues[0].radius
            }
        }
    })

    const query = new URLSearchParams(orderedQuery);
    [...query.entries()].forEach(([key, value]) => {
        (value === "" || value === "default") && query.delete(key)
    })

    return query
}
