import {setCookie} from "./cookies";

export function addTermSearchCount(termID: number) {
    return fetch(`/term-searched/${termID}`, { method: "PUT" })
}

export function savePreviousSearch(interest: [] | {}, locality: [] | {}, params: [], url: string | URL) {
    const searchInterest = Array.isArray(interest) ? interest : [interest],
        searchLocality = Array.isArray(locality) ? locality : [locality]

    if (![searchInterest, searchLocality, params].filter((details: []) => details.length).length) return

    setCookie('previousSearch', JSON.stringify({ details: {
        interest: searchInterest.map((term: FilterTerm) => term.name),
        locality: searchLocality.map((term: FilterLocalityTerm) => term.name),
        params: params.map((term: FilterTerm) => term.name)
    }, url }))
}
