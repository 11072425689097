import {createApp, reactive} from "petite-vue";
import SearchField from "../components/search-field";
import {orderQuery} from "../utils/query";
import {addTermSearchCount, savePreviousSearch} from "../utils/search";

interface SearchState {
    selectedInterest: FilterTerm[]
    selectedLocality: FilterLocalityTerm[]
    showOffers(e: SubmitEvent): void
}

export default function initSearch(): void {
    const searchForm = document.querySelector('[data-offer-search]')
    if (!searchForm || !searchConfig) return

    const search: SearchState = reactive({
        selectedInterest: [],
        selectedLocality: [],
        async showOffers(e) {
            const form = e.target as HTMLFormElement,
                promiseQueue: Promise<Response>[] = []

            const queryParams = [...this.selectedInterest, ...this.selectedLocality]
                .reduce<QueryFields>((args, term: SearchResult) => {
                    ['specialization', 'profession'].includes(term.type)
                        && promiseQueue.push(addTermSearchCount(term.id))

                    if (term.type === "city") args.radius = [...(args.radius ?? []), `${term.id}+${term.radius}`]

                    return {...args, [term.type]: [...(args[term.type] ?? []), term.slug ?? term.id]}
                }, {})

            const query = orderQuery(queryParams, searchConfig.assignedQueryOrder),
                queryString = [...query].length ? `?${decodeURIComponent(query.toString())}` : '',
                targetUrl = `${form.action}${queryString}`

            await Promise.all(promiseQueue)
            savePreviousSearch(this.selectedInterest, this.selectedLocality, [], targetUrl)
            location.assign(targetUrl)
        }
    })

    createApp({ SearchField, search }).mount(searchForm)
}
