import {isInViewport} from "../utils/is-in-viewport";
import mapStyles from "./map-styles";

export default function initMap(): void {
    const mapEl = getMapElement()

    const loadMap = () => {
        mapEl?.hasAttribute('data-loading') && !mapEl.dataset?.loading
            && isInViewport(mapEl, 8)
            && renderMapCallback()
    }

    document.addEventListener("DOMContentLoaded", loadMap)
    mapEl && document.addEventListener("scroll", loadMap)
}

const getMapElement = (): HTMLElement | null => {
    return document.querySelector('[data-map-holder]') as HTMLElement
}

const renderMapCallback = async () => {
    const mapEl = getMapElement()
    if (!mapEl) return

    mapEl.setAttribute('data-loading', 'true')
    const officeCoordinates = (mapEl.dataset?.coordinates ?? '')
            .replace(' ', '').split(',')

    const officePosition: google.maps.LatLngLiteral = {
        lat: parseFloat(officeCoordinates[0] ?? '0'),
        lng: parseFloat(officeCoordinates[1] ?? '0')
    }

    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary

    const map = new Map(mapEl, {
        center: officePosition,
        zoom: mapEl.dataset?.zoom ?? 14,
        disableDefaultUI: true,
        styles: mapStyles
    } as google.maps.MapOptions)

    if (mapEl.dataset?.marker) {
        const { Marker } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary

        const marker = new Marker({
            map,
            position: officePosition,
            icon: mapEl.dataset.marker
        })

        const addressString = mapEl.dataset?.addressString
        addressString && marker.addListener("click", () => {
            window.open(`https://www.google.com/maps/search/?api=1&query=${ addressString }`, "_blank")
        })
    }

    map.addListener("tilesloaded", () => mapEl.removeAttribute('data-loading'))
}
